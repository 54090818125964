<template>
  <ion-page>
    <Header title="Alamat Pengiriman" />
    <ion-content :fullscreen="true">
      <div class="container" v-if="$auth">
        <label for="phone">No. Handphone</label>
        <ion-input type="text" class="input" v-model="data.phone" :readonly="$auth.phone_verified"></ion-input>
        <label for="phone">Alamat Pengiriman</label>
        <ion-input type="text" class="input" v-model="data.address"></ion-input>
        <label for="phone">Kota</label>
        <ion-input type="text" class="input" v-model="data.city"></ion-input>
        <label for="phone">Kode Pos</label>
        <ion-input type="text" class="input" v-model="data.postal_code"></ion-input>

        <ion-button mode="ios" shape="round" expand="block" color="dark" class="btn" @click="save">Simpan</ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonInput, IonButton, toastController } from '@ionic/vue'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import Header from '@/components/headerPage'

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    IonInput,
    IonButton,
    Header
  },
  data () {
    return {
      data: {
        phone: null,
        address: null,
        city: null,
        postal_code: null
      }
    }
  },
  created (){
    let address = this.$auth.address
    this.data = {
      phone: this.$auth.phone,
      address: address? address.address : null,
      city: address? address.city : null,
      postal_code: address? address.postal_code : null
    }
  },
  computed: {
    ...mapGetters({
      $auth: 'auth/getLoggedIn'
    })
  },
  methods: {
    async save () {
      await this.$api.post('/api/profile', { phone: this.data.phone })
      let res = await this.$api.post('/api/address', this.data);
      if(! res.error) {
        let toast = await toastController.create({
            message: 'sucess',
            duration: 2000,
            color: 'success',
          })
        toast.present()
      }

      this.$store.dispatch('auth/fetchUser')
    }
  }
})
</script>

<style>

</style>
